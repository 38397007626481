var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"teros-elevation",attrs:{"color":_vm.tableColor}},[_c('v-card-title',{staticClass:"px-5 pb-0 text-uppercase v-title-cadastro"},[_vm._v(" Resumo do Faturamento incentivado ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex pt-0"},[_c('v-spacer'),_c('div',{staticClass:"table-v-action-button mr-3 pt-1",attrs:{"aria-label":"atualizarButton"},on:{"click":function($event){return _vm.doLoad()}}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v("Atualizar ")],1),_c('monthly-filter',{staticClass:"mr-3",staticStyle:{"width":"260px"},attrs:{"aria-label":"filtroMensal"},on:{"change":function($event){return _vm.doLoad()}},model:{value:(_vm.competencia),callback:function ($$v) {_vm.competencia=$$v},expression:"competencia"}})],1)]),_c('v-data-table',{attrs:{"id":"dashboard_ped_table","data-app":"","items":_vm.relatorioFormatado,"footer-props":{
      'items-per-page-options': [-1],
    },"hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',{staticClass:"blue lighten-5",staticStyle:{"cursor":"default"}},[_c('th',{staticStyle:{"padding-left":"1rem"},attrs:{"rowspan":"2"}},[_vm._v("Faturamento")]),_vm._l((_vm.intervaloCompetencias),function(competencia){return _c('th',{key:("competencia-" + competencia),staticStyle:{"text-align":"center"},attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(_vm._f("toMonth")(competencia))+" ")])}),_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"2"}},[_vm._v("TOTAL")])],2),_c('tr',{staticClass:"blue lighten-5",staticStyle:{"cursor":"default"}},[_vm._l((_vm.intervaloCompetencias),function(competencia){return [_c('th',{key:("ppb-" + competencia),staticStyle:{"text-align":"center"}},[_vm._v("PPB")]),_c('th',{key:("tecnac-" + competencia),staticStyle:{"text-align":"center"}},[_vm._v("TECNAC")])]}),_c('th',{key:"ppbTotal",staticStyle:{"text-align":"center"}},[_vm._v("PPB")]),_c('th',{key:"tecnacTotal",staticStyle:{"text-align":"center"}},[_vm._v("TECNAC")])],2)]},proxy:true},{key:"item",fn:function(ref){
    var item = ref.item;
return [_c('tr',{staticClass:"table-v-tr",class:{ 'font-weight-bold blue lighten-5': item.isTotal },staticStyle:{"cursor":"default"}},_vm._l((_vm.colunas),function(col){return _c('td',{key:col.value,class:{
            nowrap: col.nowrap,
            'text-end': col.align == 'end',
            'font-weight-bold': item.isTotal,
          }},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(item[col.value]))+" ")])}),0)]}}])}),_c('v-card-text',{staticClass:"pt-1"},[_c('v-row',{attrs:{"id":"dashboard_ped_chart"}},[_c('v-col',[_c('div',{staticClass:"font-weight-bold text-uppercase mb-3"},[_vm._v(" Faturamento Realizado PPB e Tecnac Acumulado ")]),_c('div',{staticStyle:{"background":"white","border-radius":"5px","padding":"20px"}},[_c('div',{staticStyle:{"height":"280px","max-width":"280px","margin":"0px auto"}},[_c('pie-chart',{attrs:{"chartdata":_vm.faturamentoRealizadoAcumulado,"options":_vm.piechartoptions}})],1)])]),_c('v-col',[_c('div',{staticClass:"font-weight-bold text-uppercase mb-3"},[_vm._v(" Faturamento Liquido Realizado PPB e Tecnac ")]),_c('div',{staticStyle:{"background":"white","border-radius":"5px","padding":"20px"}},[_c('div',{staticStyle:{"height":"280px","max-width":"280px","margin":"0px auto"}},[_c('bar-chart',{staticStyle:{"height":"280px"},attrs:{"chartdata":_vm.faturamentoRealizadoTrimestral,"options":_vm.barChartOptions}})],1)])])],1)],1),_c('v-card-text',{staticClass:"pt-0 pl-5 mb-n5"},[_c('v-row',{attrs:{"id":"dashboard_ped_chart"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"font-weight-bold",staticStyle:{"color":"var(--red)"}},[_vm._v(" "+_vm._s(_vm.mensagemInvestimento)+" ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }